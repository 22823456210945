
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Home',
  components: {},
  setup() {
    const image1 = require('@/assets/Group 21.png')
    const image2 = require('@/assets/WechatIMG4.jpeg')
    const image3 = require('@/assets/gongan.png')
    const icon1 = require('@/assets/icon1.png')
    const icon2 = require('@/assets/icon2.png')
    const icon3 = require('@/assets/icon3.png')
    const icon4 = require('@/assets/icon4.png')
    const activeIndex: any = ref('')
    function selecthash(e: any, link: any) {
      e.preventDefault()
      // let dom: any = document.querySelector(link.href)
      // const html: any = document.querySelector('html')
      // html.scrollTop = dom.offsetTop + 380
    }
    function changehash(e: any) {
      activeIndex.value = e.split('#')[1]
    }
    return {
      image1,
      icon1,
      icon2,
      icon3,
      icon4,
      activeIndex,
      selecthash,
      image2,
      image3,
      changehash
    }
  }
})
