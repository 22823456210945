<template>
  <div class="home">
    <div class="header_logo">
      <img :src="image1" alt="">
    </div>
    <div class="banner">
    </div>
    <div class="content">
      <div class="left">
        <!-- <a-affix :offset-top="0">
          <ul>
            <li @click="selecthash(1)" :class="activeIndex === 1 ? 'active': ''">关于“上文引立”清盘的公告</li>
            <li @click="selecthash(2)" :class="activeIndex === 2 ? 'active': ''"><img :src="activeIndex === 2 ? icon2: icon1" alt="">关于仓储费收费标准及收费方式的通知</li>
            <li @click="selecthash(3)" :class="activeIndex === 3 ? 'active': ''"><img :src="activeIndex === 3 ? icon4: icon3" alt="">公司简介</li>
          </ul>
        </a-affix> -->
        <a-anchor :showInkInFixed="false" @change="changehash">
          <a-anchor-link @click="selecthash" href="#card4">
            <template #title>
              <span title="关于开展邮币卡类业务整合工作进展的公告">
                <img :src="activeIndex === 'card4' ? icon2: icon1" alt="">关于开展邮币卡类业务整合工作进展的公告
              </span>
            </template>
          </a-anchor-link>
          <!-- <a-anchor-link @click="selecthash" href="#card1">
            <template #title>
              <img :src="activeIndex === 'card1' ? icon2: icon1" alt="">关于“上文引立”清盘的公告
            </template>
          </a-anchor-link> -->
          <a-anchor-link @click="selecthash" href="#card2">
            <template #title>
              <img :src="activeIndex === 'card2' ? icon2: icon1" alt="">关于仓储费收费标准及收费方式的通知
            </template>
          </a-anchor-link>
          <a-anchor-link @click="selecthash" href="#card3">
            <template #title>
              <img :src="activeIndex === 'card3' ? icon4: icon3" alt="">公司简介
            </template>
          </a-anchor-link>
        </a-anchor>
      </div>
      <div class="right">
        <div class="card" id="card4">
          <h2 style="margin-bottom: 40px">关于开展邮币卡类业务整合工作进展的公告</h2>
          <ul>
            <li>
              <p style="padding-left: 0;">各邮币卡商品持有人：</p>
              <p class="nopadding">根据《国务院办公厅关于清理整顿各类交易场所的实施意见》(国办发〔2012〕37号)的规定，以及国家和本市最近对于清理整顿工作的最新要求，拟将上海文化产权交易所、上海工美艺术品交易中心邮币卡类相关业务整合移入上海邮币卡交易中心（以下简称“上邮中心”）。</p>
              <p class="nopadding">各家平台将于近期共同公告，发布整合工作流程和相关操作细则。邮币卡类商品持有人可自主选择是否参与业务整合。愿意参与业务整合的邮币卡商品持有人可根据后续公告流程在原平台及上邮中心办理相关业务；不愿参与整合的商品持有人可根据原平台后续安排办理相关手续。上邮中心新用户注册等功能也将视整合工作的推进情况逐步开放。</p>
              <p class="nopadding">特此公告。</p>
            </li>
          </ul>

          <p style="text-align: right">引立文化艺术品运营服务平台</p>
          <p style="text-align: right">2022年3月11日</p>
        </div>
        <!-- <div class="card" id="card1">
          <h2 style="margin-bottom: 40px">关于“上文引立”清盘的公告</h2>
          <p class="nopadding">上海文化产权交易所引立运营服务平台（上文引立）自2017年6月停盘起，已历时4年有余。4年多来，按照上海市交易场所清理整顿的要求，客户提货工作已基本完成。截至2021年8月31日，“上文引立”的货品提货率已达99.73%，为进一步实现清理整顿工作目标，经研究决定，“上文引立”平台将于2021年10月31日清盘，现将有关事项公告如下：</p>
          <ul>
            <li>
              <div><span>一、</span>所有“上文引立”客户须于2021年10月31日前完成提货、出金。</div>
              <ul>
                <li>
                  <p><span>1.</span>提货咨询电话：021-36129928，外地客户可通过邮寄方式提货（邮费到付）；</p>
                  <p><span>2.</span>提货完成后，客户可通过登录上海文化产权交易所官网 <a href="https://www.shcaee.com/" target="_blank">www.shcaee.com</a> 进行全额出金操作。</p>
                </li>
              </ul>
              <div><span>二、</span>2021年10月31日后，“上文引立”平台所有账户全部清盘，相关系统不再进行维护，由此造成的后果由客户承担。</div>
              <div><span>三、</span>2021年10月31日后，“上文引立”平台对未提货品采用“另库存放”的方式，提供一年期仓储服务，仓储费由未提货的客户自付。（收费标准见上文引立官网2018年8月15日《关于仓储费收费标准及收费方式的通知》，《通知》全文附后。）</div>
              <div><span>四、</span>“另库存放”期间客户如果需要提货，须先缴交仓储费后方可提货，缴费时间自2018年9月1日起至提货当天的自然日进行计算。 </div>
              <div><span>五、</span>一年期“另库存放”后，即自2022年11月1日起，上文引立平台将对未提货品依法进行处置（如通过公开拍卖等形式），在抵扣客户未提货品的仓储费后，若仍有资金剩余，需客户自行至上海文交所官网进行出金操作。 </div>
            </li>
          </ul>
          <p style="padding-left: 15px">特此公告！</p>
          <p style="text-align: right">上海文化产权交易所引立文化艺术品运营服务平台</p>
          <p style="text-align: right">2021年9月7日</p>
        </div> -->
        <div class="card" id="card2">
          <h2>关于仓储费收费标准及收费方式的通知</h2>
          <div class="tips">上文引立通【2018】0018号</div>
          <p class="nopadding">根据国家相关规定和【20180718】上文引立通0014号《关于托管组权商品收取仓储费的通知》，决定自2018年9月1日起对各托管组权商品收取存货仓储费，具体事项如下：</p>
          <ul>
            <li>
              <div><b><span>一、</span>各托管组权仓储费标准</b></div>
            </li>
          </ul>
          <div style="padding-left: 15px">
            <table border="1" style="margin: auto" cellspacing="0">
              <thead>
                <tr>
                  <th width="228">组权代码</th>
                  <th width="306">组权简称</th>
                  <th width="356">仓储费标准</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>6006001</td>
                  <td>53壹分有油</td>
                  <td>0.001元/天/张</td>
                </tr>
                <tr>
                  <td>6006002</td>
                  <td>分币八连体</td>
                  <td>0.08元/天/册</td>
                </tr>
                <tr>
                  <td>6006003</td>
                  <td>伍圆外汇券</td>
                  <td>0.01元/天/张</td>
                </tr>
                <tr>
                  <td>6006005</td>
                  <td>航天钞</td>
                  <td>0.01元/天/张</td>
                </tr>
                <tr>
                  <td>6006006</td>
                  <td>建国钞</td>
                  <td>0.01元/天/张</td>
                </tr>
                <tr>
                  <td>6006007</td>
                  <td>1953年贰分</td>
                  <td>0.001元/天/张</td>
                </tr>
                <tr>
                  <td>6006008</td>
                  <td>奥运钞</td>
                  <td>0.01元/天/张</td>
                </tr>
                <tr>
                  <td>6006009</td>
                  <td>外汇券壹角</td>
                  <td>0.01元/天/张</td>
                </tr>
                <tr>
                  <td>6006011</td>
                  <td>航天钞标准百连</td>
                  <td>0.01元/天/张</td>
                </tr>
                <tr>
                  <td>8008001</td>
                  <td>二轮彩银龙</td>
                  <td>0.02元/天/枚</td>
                </tr>
                <tr>
                  <td>8008002</td>
                  <td>15熊猫银币</td>
                  <td>0.02元/天/枚</td>
                </tr>
                <tr>
                  <td>8008003</td>
                  <td>2011银猫1oz</td>
                  <td>0.02元/天/枚</td>
                </tr>
                <tr>
                  <td>8008005</td>
                  <td>2015年金猫1/20oz</td>
                  <td>0.02元/天/枚</td>
                </tr>
                <tr>
                  <td>9009001</td>
                  <td>一轮牛纪念币</td>
                  <td>0.01元/天/枚</td>
                </tr>
                <tr>
                  <td>9009002</td>
                  <td>抗战70周年纪念币</td>
                  <td>0.01元/天/枚</td>
                </tr>
                <tr>
                  <td>9009003</td>
                  <td>航天纪念币</td>
                  <td>0.01元/天/枚</td>
                </tr>
                <tr>
                  <td>9009005</td>
                  <td>二轮猴纪念币</td>
                  <td>0.01元/天/枚</td>
                </tr>
                <tr>
                  <td>9009006</td>
                  <td>和4纪念币</td>
                  <td>0.01元/天/枚</td>
                </tr>
                <tr>
                  <td>9009007</td>
                  <td>二轮羊纪念币</td>
                  <td>0.01元/天/枚</td>
                </tr>
                <tr>
                  <td>9009008</td>
                  <td>环保2纪念币</td>
                  <td>0.01元/天/枚</td>
                </tr>
                <tr>
                  <td>9009009</td>
                  <td>和字3纪念币</td>
                  <td>0.01元/天/枚</td>
                </tr>
                <tr>
                  <td>9009010</td>
                  <td>一轮蛇纪念币</td>
                  <td>0.01元/天/枚</td>
                </tr>
                <tr>
                  <td>9009011</td>
                  <td>一轮马纪念币</td>
                  <td>0.01元/天/枚</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="padding-left: 15px"><b>注：上表仓储费中，“天”指自然日。</b></div>
          <ul>
            <li>
              <div><b><span>二、</span>仓储费收取方式</b></div>
              <ul>
                <li>
                  <p><span>1、</span>持仓账户有资金的，从账户资金中收取仓储费；</p>
                  <p><span>2、</span>持仓账户无资金的，须先缴交仓储费后方可提货；</p>
                  <p><span>3、</span>持仓账户无资金且2018年12月31日前仍未缴交仓储费的，上文引立及上海文交所有权不再保管该组权商品，且不承担任何经济及法律责任。</p>
                </li>
              </ul>
              <div><span>三、</span>因近期提货客户较多，提货日期可能会安排在2018年9月1日以后，但只要在2018年8月31日前预约提货的，仍可免交仓储费。</div>
              <div><span>四、</span>外地客户可通过邮寄方式提货，详情请来电咨询。</div>
              <p>提货地址：上海市浦东新区东方路3539号1号楼西门</p>
              <p>客服电话：021-36129990</p>
            </li>
          </ul>
          <div style="padding-left: 15px">特此通知！</div>
          <div style="text-align: right">上海文化产权交易所引立文化艺术品运营服务平台</div>
          <div style="text-align: right">二〇一八年八月十五日</div>
        </div>
        <div class="card" id="card3" style="height: calc(100vh - 204px)">
          <h2 style="margin-bottom: 40px">公司简介</h2>
          <p class="nopadding">上海引立文化发展有限公司专注于文化艺术品投资领域，是上海文化产权交易所的战略合作伙伴，设立了上海文化产权交易所（引立）文化艺术品运营服务平台，力争打造一个囊括鉴评、仓储、交易、融资、物流、清算等服务的生态圈，以期满足投资者、收藏爱好者等的多元化需求，促进行业的纵深发展。</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <a target="_blank" href="http://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020160704094444000001007469-SAIC_SHOW_310000-20200405115616513200&signData=MEUCIQDQGijp3vcYPxzA/nqErtOHtAyR8BN9YBDvjcddApjnDwIgZjAvd7PGVNttoKX/d69AVzOzsx50rAnFpLTNBLL/3xU="><img :src="image2" alt="">&nbsp;
      </a><span>备案/许可证编号：</span>
      <a href="http://www.beian.gov.cn/portal/index" target="_blank">沪ICP备16029019号-1</a>&nbsp;&nbsp;&nbsp;
      <img :src="image3" alt="">&nbsp;
      <span>京公网备案</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Home',
  components: {},
  setup() {
    const image1 = require('@/assets/Group 21.png')
    const image2 = require('@/assets/WechatIMG4.jpeg')
    const image3 = require('@/assets/gongan.png')
    const icon1 = require('@/assets/icon1.png')
    const icon2 = require('@/assets/icon2.png')
    const icon3 = require('@/assets/icon3.png')
    const icon4 = require('@/assets/icon4.png')
    const activeIndex: any = ref('')
    function selecthash(e: any, link: any) {
      e.preventDefault()
      // let dom: any = document.querySelector(link.href)
      // const html: any = document.querySelector('html')
      // html.scrollTop = dom.offsetTop + 380
    }
    function changehash(e: any) {
      activeIndex.value = e.split('#')[1]
    }
    return {
      image1,
      icon1,
      icon2,
      icon3,
      icon4,
      activeIndex,
      selecthash,
      image2,
      image3,
      changehash
    }
  }
})
</script>

<style lang="less" scoped>
.header_logo {
  padding: 20px;
  width: 350px;
  text-align: right;
  position: absolute;
  img {
    margin-top: 20px;
  }
}
ul,
li {
  list-style: none;
  padding: 0;
}
.banner {
  height: 350px;
  background: url('../assets/yinlibanner.png') center 0 no-repeat;
}
.content {
  width: 1210px;
  margin: 0 auto;
  position: relative;
  margin-top: 20px;
  .left {
    width: 280px;
    position: absolute;
    overflow: hidden;
    left: 0;
    .ant-anchor-wrapper {
      padding: 0;
      margin: 0;
      overflow: hidden;
      .ant-anchor-link {
        cursor: pointer;
        padding: 0;
        height: 80px;
        font-size: 14px;
        line-height: 80px;
        border-bottom: 1px solid #e1e6f0;
        text-align: left;
        color: #8b8b8b;
        img {
          vertical-align: middle;
          margin-right: 6px;
        }
        :deep(.ant-anchor-link-title) {
          color: rgba(139, 139, 139, 1);
          // &:hover {
          //   color: #d3474f !important;
          // }
        }
      }
      .ant-anchor-link:hover {
        background: -webkit-linear-gradient(left, #fff, #fcebec); /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right, #fff, #fcebec); /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right, #fff, #fcebec); /* Firefox 3.6 - 15 */
        background: linear-gradient(to right, #fff, #fcebec); /* 标准的语法 */
      }
      .ant-anchor-link.ant-anchor-link-active {
        color: #d3474f;
        // background: linear-gradient(red, blue);
        background: -webkit-linear-gradient(left, #fff, #fcebec); /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right, #fff, #fcebec); /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right, #fff, #fcebec); /* Firefox 3.6 - 15 */
        background: linear-gradient(to right, #fff, #fcebec); /* 标准的语法 */
        position: relative;
        :deep(.ant-anchor-link-title) {
          color: #d3474f;
        }
      }
      .ant-anchor-link.ant-anchor-link-active:after {
        width: 3px;
        position: absolute;
        background: #d3474f;
        top: 0;
        content: '';
        bottom: 0;
        right: 0;
      }
    }
  }
  .right {
    padding-left: 280px;
    width: 1210px;
    .card {
      box-shadow: 0 0 10px #e1e6f0;
      width: 920px;
      padding: 20px;
      margin-top: 30px;
      box-sizing: border-box;
      h2 {
        font-size: 32px;
        color: #a72126;
        text-align: center;
        margin-bottom: 0;
        font-weight: bolder;
      }
      .tips {
        text-align: center;
        margin-bottom: 40px;
        margin-top: 0px;
        text-indent: 0;
      }
      h4 {
        font-size: 14px;
        font-size: 12pt;
        text-indent: 2em;
      }
      p {
        font-size: 12pt;
        margin: 14px 0;
        // text-indent: 2em;
        line-height: 24px;
        font-family: '宋体';
        padding-left: 2em;
      }
      div {
        margin: 14px 0;
        font-size: 12pt;
        font-family: '宋体';
        padding-left: 2em;
      }
      p span {
        float: left;
      }
      p.nopadding,
      div.nopadding {
        padding-left: 0;
        text-indent: 2em;
      }
      div span {
        // float: left;
        margin-left: -2em;
      }
      a{
        &:hover{
          text-decoration: underline;
        }
      }
      ul {
        li {
          padding-left: 15px;
          p {
            span {
              margin-left: -15px;
            }
          }
        }
      }
      table {
        font-family: '宋体';
        th {
          padding: 4px 0px;
          font-size: 12pt;
          text-align: center;
        }
        td {
          text-align: center;
          padding: 4px 0px;
          font-size: 12pt;
        }
        td:nth-child(3) {
          font-weight: bold;
        }
      }
    }
  }
}
.footer {
  background: url(../assets/bottom_bg.png);
  height: 154px;
  width: 100%;
  margin-top: 50px;
  text-align: center;
  padding-top: 45px;
  font-size: 13px;
  min-width: 1230px;
  a {
    color: #fff;
    img {
      width: 20px;
    }
  }
  span {
    color: #fff;
  }
  a:hover {
    color: #a72126;
  }
}
.home {
  min-width: 1230px;
}
</style>
